import { useQuery } from 'react-query'
import {apiClient, ApiError} from '../api'
import {FeatureUsage, TestingMinutesUsage} from '../api/client'
import {FeatureCheck} from "../utils/features";

export const useTestingMinutesCheck = (owner: string, isIndividual: (owner: string) => boolean, enabled: boolean) => {
    const testingMinutesQuery = useQuery<FeatureUsage | null, ApiError>({
        queryKey: ['useTestingMinutesCheck', owner],
        queryFn: async () => {
            if (isIndividual(owner)) {
                return apiClient.users.usersUsageDetails({ feature: 'testing_minutes' })
            } else {
                return apiClient.organizations.organizationsUsageDetails({organization: owner, feature: 'testing_minutes'})
            }
        },
        enabled,
        retry: false,
    })

    const checkTestingMinutes = () => {
        if (testingMinutesQuery.data === null) {
            return FeatureCheck.NotIncluded()
        }
        if (testingMinutesQuery.data !== undefined) {
            const data = testingMinutesQuery.data as TestingMinutesUsage
            if (data.remaining === null || data.remaining !== 0 ) {
                return FeatureCheck.Passed(data.usage, data.feature.amount);
            } else{
                return FeatureCheck.LimitExceeded(data.usage, data.feature.amount);
            }
        } else {
            return FeatureCheck.Loading()
        }
    }

    return { testingMinutes: checkTestingMinutes() }
}
