import React from 'react'
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Tooltip } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { FeatureCheck, FeatureCheckStatus } from '../../../../../utils/features'
import { ButtonState } from 'models/Button'
import { ProjectEnvironment } from '../../../../../api/client'

export const RunTestButton: React.FC<{
    title?: string
    size?: 'small' | 'medium' | 'large'
    state: ButtonState
    startTest: () => void
    stopTest: () => void
    testingMinutes: FeatureCheck
    environments: ProjectEnvironment[] | undefined
}> = ({ title, size, state, startTest, stopTest, testingMinutes, environments }) => {
    const defaultEnvironment = environments?.find((env) => env.is_default)
    const hasNoDefaultEnvironment = !(environments !== undefined && defaultEnvironment !== undefined)

    const isInProgress = state === ButtonState.IN_PROGRESS
    const text = (() => {
        switch (state) {
            case ButtonState.IDLE:
                return title
            case ButtonState.IN_PROGRESS:
                return 'Cancel test'
            case ButtonState.BEING_CANCELLED:
                return 'Cancelling'
        }
    })()
    const tooltip = (() => {
        if (hasNoDefaultEnvironment) {
            return 'No default environment set'
        }
        switch (testingMinutes.getStatus()) {
            case FeatureCheckStatus.LOADING:
                return 'Loading ...'
            case FeatureCheckStatus.NOT_INCLUDED:
                return 'Your current subscription does not include test minutes for running tests on our servers.'
            case FeatureCheckStatus.LIMIT_EXCEEDED:
                return 'Your current subscription has reached its limit for test minutes.'
            case FeatureCheckStatus.PASSED:
                switch (state) {
                    case ButtonState.IDLE:
                        return 'Run tests now'
                    case ButtonState.IN_PROGRESS:
                        return 'Test is in progress'
                    case ButtonState.BEING_CANCELLED:
                        return 'Cancelling'
                }
        }
    })()

    return (
        <Tooltip title={tooltip}>
            <div
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation()
                    event.preventDefault()
                }}
            >
                <LoadingButton
                    variant="contained"
                    key="test"
                    size={size || 'small'}
                    loading={state === ButtonState.BEING_CANCELLED}
                    color={isInProgress ? 'error' : 'primary'}
                    disabled={!testingMinutes.isPassed() || hasNoDefaultEnvironment}
                    onClick={() => {
                        if (isInProgress) {
                            stopTest()
                        } else {
                            startTest()
                        }
                    }}
                    loadingPosition="start"
                    startIcon={state === ButtonState.IN_PROGRESS ? <CancelOutlinedIcon /> : <PlayCircleOutlinedIcon />}
                    sx={{
                        width: 140,
                    }}
                >
                    {text}
                </LoadingButton>
            </div>
        </Tooltip>
    )
}
