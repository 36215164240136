import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Alert, Typography } from '@mui/material'
import { NewAPIRequest, apiClient, ApiError, APIErrorResponse } from 'api'
import { useUsers } from 'contexts/UsersContext'
import { useOrganizations } from 'queries/useOrganizations'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useMutation } from 'react-query'
import { buildPotentialOwners } from 'utils/access'
import React, { useEffect, useRef, useState } from 'react'
import { URLS } from '../../../urls'
import { useNavigate } from 'react-router-dom'
import { trackCreateProjectEvent } from './events'
import ApiEnvironmentsForm from 'features/components/environments'
import { EnvironmentsFormType } from 'features/modelts/environments'
import { ProjectDefaults } from 'api/client/models/ProjectDraft'

type FormType = {
    organization: string | null
    name: string
    draft_id: string
    auth: 'NoAuth' | 'HttpBasic' | 'Header'
    username: string
    password: string
    headerName: string
    headerValue: string
} & EnvironmentsFormType

const defaultData = {
    organization: null,
    name: '',
    draft_id: '',
    environments: [],
    auth: 'NoAuth' as FormType['auth'],
    username: '',
    password: '',
    headerName: '',
    headerValue: '',
}

const mapDefaults = (draft: ProjectDefaults | undefined) => {
    return {
        organization: draft?.organization || null,
        name: draft?.name || '',
        draft_id: draft?.id || '',
        environments: draft?.environments || [],
    }
}

type EnvFormHandle = React.ElementRef<typeof ApiEnvironmentsForm>

const ConfigurationCreateForm: React.FC<{
    defaults: ProjectDefaults | undefined
    fastTrackRef: React.MutableRefObject<boolean>
}> = ({ defaults, fastTrackRef }) => {
    const envFormRef = useRef<EnvFormHandle>(null)
    const methods = useForm<FormType>({
        defaultValues: { ...defaultData, ...mapDefaults(defaults) },
    })
    const {
        clearErrors,
        setError,
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = methods

    const [apiError, setApiError] = useState<string | undefined>(undefined)
    const navigate = useNavigate()
    const auth = watch('auth')
    const organization = watch('organization')
    const { data } = useOrganizations({ queryKey: ['useOrganizations'] })
    const { user } = useUsers()
    const potentialOwners = buildPotentialOwners({ organizations: data?.items })
    const owner = organization || user?.username || ''
    const createProjBtnRef = useRef<HTMLButtonElement | null>(null)

    const createAPIMutation = useMutation(
        async (requestBody: NewAPIRequest) => await apiClient.apis.apisCreate({ requestBody }),
        {
            onMutate: () => {
                clearErrors()
            },
            onSuccess: ({ project }) => {
                trackCreateProjectEvent('submit_form_success')
                fastTrackRef.current = false
                navigate(URLS.projects.gettingStartedNew.buildPath({ id: project.id }))
            },
            onError: (response: ApiError) => {
                trackCreateProjectEvent('submit_form_error')
                clearErrors()
                if (fastTrackRef.current) createProjBtnRef.current?.scrollIntoView({ behavior: 'smooth' })
                fastTrackRef.current = false
                const data: APIErrorResponse = response.body
                if (data.errors) {
                    for (const [name, fieldErrors] of Object.entries(data.errors)) {
                        // @ts-expect-error
                        for (const error of fieldErrors) {
                            // @ts-expect-error
                            setError(name, { type: 'custom', message: error.message })
                        }
                    }
                } else {
                    setApiError(data.detail)
                }
            },
        },
    )

    useEffect(() => {
        if (!defaults) return

        const data = mapDefaults(defaults)
        for (const [key, value] of Object.entries(data)) {
            setValue(key as keyof FormType, value)
        }

        if (fastTrackRef.current) {
            const formOrg = getValues('organization')
            onSubmit({
                organization: formOrg === user?.username ? null : formOrg,
                name: defaults.name || 'Example API',
                draft_id: defaults.id,
                environments: defaults.environments,
                auth: 'NoAuth',
                username: '',
                password: '',
                headerName: '',
                headerValue: '',
            })
        }
        // FIXME: eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaults, setValue])

    function onSubmit(form: FormType) {
        trackCreateProjectEvent('submit_form')
        createAPIMutation.mutate({
            organization: form.organization === user?.username ? null : form.organization,
            name: form.name,
            draft_id: form.draft_id,
            environments: form.environments,
            auth:
                form.auth === 'Header'
                    ? {
                          type: 'Header',
                          name: form.headerName,
                          value: form.headerValue,
                      }
                    : form.auth === 'HttpBasic'
                      ? {
                            type: 'HttpBasic',
                            username: form.username,
                            password: form.password,
                        }
                      : null,
        })
    }

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    envFormRef.current?.beforeSubmit()
                    handleSubmit(onSubmit)(e)
                    envFormRef.current?.afterSubmit()
                }}
            >
                <div className="section-block">
                    <Alert icon={false} color="info" sx={{ mb: 2 }}>
                        <Typography variant="body2" fontWeight="bold">
                            After setting up your project, you have two options:
                        </Typography>
                        <br />
                        <Typography variant="body2">
                            🌐 Use our platform for easy testing and access to additional features
                        </Typography>
                        <br />
                        <Typography variant="body2">
                            🔧 Use our open-source CLI to run tests on your own hardware, completely free.
                        </Typography>
                    </Alert>

                    <div className="form-row small">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>Owner</InputLabel>
                            <Controller
                                name="organization"
                                control={control}
                                render={({ field }) => (
                                    <Select {...field} variant="standard" value={owner}>
                                        {potentialOwners.map((owner) => {
                                            const key = owner.value || user?.username || ''
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    {key}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>

                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: { value: true, message: 'Name is required' } }}
                            render={({ field }) => (
                                <TextField
                                    label="Name"
                                    variant="standard"
                                    placeholder="My Project"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...field}
                                    error={!!errors.name}
                                    helperText={errors?.name?.message || ''}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="section-block">
                    <div className="form-row small">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>Authentication</InputLabel>
                            <Controller
                                name="auth"
                                control={control}
                                rules={{ required: { value: true, message: 'required' } }}
                                render={({ field }) => (
                                    <Select {...field} variant="standard">
                                        <MenuItem value="NoAuth">No auth</MenuItem>
                                        <MenuItem value="HttpBasic">HTTP Basic</MenuItem>
                                        <MenuItem value="Header">Header</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </div>
                    {auth === 'HttpBasic' ? (
                        <div className="form-row small">
                            <Controller
                                name="username"
                                control={control}
                                rules={{}}
                                render={({ field }) => (
                                    <TextField
                                        id="username"
                                        label="Username"
                                        variant="standard"
                                        {...field}
                                        fullWidth
                                        placeholder="Username"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                rules={{}}
                                render={({ field }) => (
                                    <TextField
                                        label="Password"
                                        variant="standard"
                                        {...field}
                                        fullWidth
                                        placeholder="Password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    ) : null}
                    {auth === 'Header' ? (
                        <div className="form-row small">
                            <Controller
                                name="headerName"
                                control={control}
                                rules={{}}
                                render={({ field }) => (
                                    <TextField
                                        label="Header name"
                                        variant="standard"
                                        {...field}
                                        fullWidth
                                        placeholder="Name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="headerValue"
                                control={control}
                                rules={{}}
                                render={({ field }) => (
                                    <TextField
                                        label="Header value"
                                        variant="standard"
                                        {...field}
                                        fullWidth
                                        placeholder="Value"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    ) : null}
                    {apiError && (
                        <Alert severity="error" sx={{ my: 2 }}>
                            {apiError}
                        </Alert>
                    )}
                </div>
                <div className="section-block content-rows">
                    <h3>Environments</h3>
                    <p className="text-sm text-gray-700">
                        Environments enable testing in different setups like staging or pre-production, verifying
                        functionality under various conditions.
                    </p>
                    <ApiEnvironmentsForm ref={envFormRef} isLoading={false} />
                </div>
                <div className="section-actions">
                    <Button ref={createProjBtnRef} type="submit" variant="contained" disabled={!defaults}>
                        Create project
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default ConfigurationCreateForm
